@use "@/styles/variables" as *;
@use "@/styles/utils" as *;

.headerWrapper {
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 0;
  left: 0;
  z-index: $header-layer;
  width: 100%;
}

.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  height: $header-height;
  background: $color-header-background;
  color: $color-header-foreground;
  padding: 0 $space-sm;
  position: relative;

  border-bottom: 1px solid $color-header-border;

  z-index: inherit;

  @include phone {
    padding: 0 $space-xxxs;
  }

  @include tablet {
    padding: 0 $space-md;
  }

  @include desktop {
    height: $header-height-desktop;
    padding: 0 $space-xxl;
  }
}
